import './App.css';
import { About } from './Component/Pages/About';
import { Contact } from './Component/Pages/Contact';
import Home from './Component/Pages/Home';
import {Route,Routes} from 'react-router'
import { Product } from './Component/Pages/Shop/Product';
import { ShopingCard } from './Component/Pages/Shop/ShopingCard';
import { CheckOut } from './Component/Pages/Shop/CheckOut';
import Register from './Component/Pages/Register';
import Profile from './Component/Pages/Profile';
import ProductDetails from './Component/Pages/ProductDetails';
import AllCart from './Component/Pages/AllCart';
import TrackOrder from './Component/Pages/TrackOrder';
import Otp from './Component/Pages/Otp';
import StorePolicy from './Component/Pages/StorePolicy';
import TermOfSerices from './Component/Pages/TermOfSerices';
import ShippingPolicy from './Component/Pages/ShippingPolicy';
import OrderDetails from './Component/Pages/OrderDetails';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearDataIfInvalid } from './Component/Redux/user_information';

function App() {
  const dispatch = useDispatch();

  // Function to check if the page is being refreshed or closed
  const isPageRefreshed = () => {
    return window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;
  };

  useEffect(() => {
    if (!isPageRefreshed()) {
      dispatch(clearDataIfInvalid());
    }
    const handleBeforeUnload = () => {
      sessionStorage.setItem('isPageBeingRefreshed', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);
  useEffect(() => {
    const refreshFlag = sessionStorage.getItem('isPageBeingRefreshed');
    if (refreshFlag) {
      sessionStorage.removeItem('isPageBeingRefreshed');
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        <Route path="/AllProduct" element={<Product/>}/>
        <Route path="/ShopingCard" element={<ShopingCard/>}/>
        <Route path="/Checkout" element={<CheckOut/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/Profile" element={<Profile/>}/>
        <Route path="/productDetails" element={<ProductDetails/>} />
        <Route path="/Cart" element={<AllCart/>}/>
        <Route path="/Track-order" element={<TrackOrder/>}/>
        <Route path="/Otp" element={<Otp/>}/>
        <Route path="/store-policy" element={<StorePolicy/>}/>
        <Route path="/term-services" element={<TermOfSerices/>}/>
        <Route path="/shipping-policy" element={<ShippingPolicy/>}/>
        <Route path="/order-details" element={<OrderDetails/>}/>
      </Routes>
    </div>
  );
}

export default App;
