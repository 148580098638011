import api from "../api";
import {Signup_endpoint,search_endpoint,contact_endpoint,checkCoupon_endpoint,Logout_endpoint,Category_endpoint,Product_endpoint,PlaceOrder_endpoint,state_endpoint,orderCreate_endpoint,profile_endpoint,Coupon_endpoint,CancelOrder_endpoint} from '../api_endpoints'
const {SIGNUP_API,OTP_API} = Signup_endpoint;
const {LOGOUT_API} = Logout_endpoint;
const {CATEGORY_API,CATEGORYPRODUCT_API} = Category_endpoint;
const { PRODUCT_API,PRODUCT_DETAILS_API} = Product_endpoint
const {PLACEORDER_API} = PlaceOrder_endpoint
const {state_endpoint_API} = state_endpoint
const {orderValidate_endpoint_API,singleOrder_endpoint_API, orderValidate_endpoint_cod_API} = orderCreate_endpoint
const {PROFILE_endpoint_API,PROFILEUPDATE_endpoint_API} = profile_endpoint
const {COUPON_API,BANNER_API} = Coupon_endpoint
const {CANCELORDER_endpoint_API} = CancelOrder_endpoint
const {COUPON_endpoint_API} = checkCoupon_endpoint
const {CONTACT_endpoint_API} = contact_endpoint
const {search_endpoint_API} = search_endpoint

export const Signup = async (data) => {
    let result = [];
    try {
        console.log("data : ",data)
        const response = await api.post(SIGNUP_API,data);
        console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}
export const otpVerify = async (data) => {
    let result = [];
    try {
        //console.log("data : ",data)
        const response = await api.post(OTP_API,{otp:data});
        //console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}
export const getLogout = async (data) => {
    let result = [];
    try {
        //console.log("data : ",data)
        const response = await api.post(LOGOUT_API,data);
        //console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}
export const getCategory = async () => {
    let result = [];
    try {
        const response = await api.get(CATEGORY_API);
        //console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}
export const getProduct = async (sortOption) => {
    let result = [];
    try {
        console.log("sort Type : ",sortOption)
        const response = await api.get(`${PRODUCT_API}?sort_by=${sortOption}`);
        console.log("Product : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}
export const getProductDetails = async (id) => {
    let result = [];
    try {
        const product_id = id;
        //console.log("product_id in ApI : ",product_id);
        const response = await api.get(`${PRODUCT_DETAILS_API}?product_url=${product_id}`);
        
        //console.log("Product details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};
export const placeOrder = async (data) => {
    let result = [];
    try {
        //console.log("Data : ",data);
        const response = await api.post(PLACEORDER_API,data);
        
        //console.log("Product details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};
export const getState = async () => {
    let result = [];
    try {
        const response = await api.get(state_endpoint_API);
        
        //console.log("Product details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const orderCreate = async (data) => {
    let result = [];
    try {
        //console.log("Data : ",data);
        const response = await api.post(PLACEORDER_API,data);
        
        //console.log("Order data: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};
export const orderValidate = async (data) => {
    let result = [];
    try {
        //console.log("Data : ",data);
        const response = await api.post(orderValidate_endpoint_API,data);
        
        //console.log("order validate : ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const orderValidateCod = async (data) => {
    let result = [];
    try {
        //console.log("Data : ",data);
        const response = await api.post(orderValidate_endpoint_cod_API,data);
        
        //console.log("order validate : ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const getCategoryProduct = async (id) => {
    let result = [];
    try {
        const response = await api.get(`${CATEGORYPRODUCT_API}?subcategory_id=${id}`);
        
        //console.log("Product details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const    getProfile = async (token) => {
    let result = [];
    try {
        const response = await api.get(`${PROFILE_endpoint_API}?access_token=${token}`);
        
        console.log("Profile details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const getSingleOrderDetails = async (id) => {
    let result = [];
    try {
        const response = await api.get(`${singleOrder_endpoint_API}?order_id=${id}`);
        
        //console.log("order details: ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};
export const profileUpdate = async (data) => {
    let result = [];
    try {
        //console.log("Data : ",data);
        const response = await api.post(PROFILEUPDATE_endpoint_API,data);
        
        //console.log("profile update : ", response.data);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const getCoupon = async () => {
    let result = [];
    try {
        const response = await api.get(COUPON_API);
        console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}

export const getBanner = async () => {
    let result = [];
    try {
        const response = await api.get(BANNER_API);
        //console.log("response : ",response.data)
        result = response.data;
    }
    catch(error) {
        //console.log("GET_RESTURENT_API_ERROR : ",error)
    }
    return result;
}

export const cancelOrder = async (data) => {
    let result = [];
    try {
        console.log("cancel data : ",data);
        const response = await api.post(CANCELORDER_endpoint_API,data);
        
        console.log("cancel order : ", response);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const checkCoupon = async (data) => {
    let result = [];
    try {
        console.log("cancel data : ",data);
        const response = await api.post(COUPON_endpoint_API,data);
        
        console.log("cancel order : ", response);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const postEmail = async (data) => {
    let result = [];
    try {
        console.log("contact : ",data);
        const response = await api.post(CONTACT_endpoint_API,data);
        
        console.log("Contact email : ", response);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};

export const search_api = async (data) => {
    let result = [];
    try {
        console.log("Search name : ",data);
        const response = await api.get(`${search_endpoint_API}?type_text=${data}`);
        
        console.log("api search data : ", response);
        result = response.data;
    } catch (error) {
        //console.log("GET_PRODUCT_DETAILS_API_ERROR: ", error);
    }
    return result;
};