import React, { useState } from "react";
import { Navbar } from "../Core/Navbar";
import "../Styles/Contact.css";
import { postEmail } from "../Services/Operations/ProductAPI";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // Validate mobile number length
    if (data.mobile_no.length !== 10) {
      toast.error("Mobile number must be exactly 10 digits.");
      return; // Exit the function if validation fails
    }

    try {
      const result = await postEmail(data);
      if (result.success) {
        toast.success("Mail is sent.....");
      }
    } catch (error) {
      toast.error("Failed to send the query.");
    }

    // Reset form (optional)
    alert("Query Submitted!");
  };

  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <nav
          className="breadcrumb"
          style={{
            marginBottom: "0px",
            fontFamily: "Arboria",
            display: "flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <span>HOME</span> &gt; <span>OB CONNECT</span>
        </nav>

        <div className="main-section" style={{ margin: "0px" }}>
          {/* <img
            src={require("../../Assets/Img/Item.png")}
            alt="OB Connect"
            className="main-image"
            style={{ height: "575px", objectFit: "fill", borderRadius: "0px" }}
          /> */}
          <div className="join">
            <h1
              className="main-title"
              style={{
                fontFamily: "Helvetica Neue",
                fontStyle: "italic",
                fontWeight: "bold",
                fontSize: "36px",
                textAlign: "left",
              }}
            >
              ob connect
            </h1>
            <p
              className="main-description"
              style={{
                fontFamily: "SF Pro",
                lineHeight: "15px",
                textAlign: "justify",
              }}
            >
              Should you have any questions about our privacy practices or this
              Privacy Policy, or if you would like to exercise any of the rights
              available to you, please call [TOLL FREE TELEPHONE NUMBER IF YOU
              HAVE A PHYSICAL RETAIL LOCATION] or email us at support@overbar.in
              or contact us at 1103, Marina Building NL Road Bandra West, Near
              Lucky Restaurant, 400050 Mumbai MH, India.
            </p>
            <button className="join-btn" style={{ fontFamily: "Arboria" }}>
              JOIN US
            </button>
          </div>
        </div>

        <div className="query-section">
          <h2
            className="query-header"
            style={{
              fontFamily: "Helvetica Neue",
              fontStyle: "italic",
              fontWeight: "bold",
              fontSize: "36px",
              textAlign: "left",
            }}
          >
            have a query?
          </h2>
          <p
            className="query-description"
            style={{
              fontFamily: "SF Pro",
              lineHeight: "15px",
              textAlign: "justify",
            }}
          >
            send details in below
          </p>

          <form className="query-form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              name="full_name"
              placeholder="YOUR NAME"
              {...register("full_name", { required: "Full name is required" })}
              className="input-field"
            />
            {errors.full_name && (
              <p  style={{ textAlign: "left", width: "100%" }} className="error-message">{errors.full_name.message}</p>
            )}

            <input
              type="email"
              name="email"
              placeholder="EMAIL ADDRESS"
              {...register("email", { required: "Email address is required" })}
              className="input-field"
            />
            {errors.email && (
              <p
                style={{ textAlign: "left", width: "100%" }}
                className="error-message"
              >
                {errors.email.message}
              </p>
            )}

            <input
              type="text"
              name="mobile_no"
              placeholder="PHONE NUMBER"
              {...register("mobile_no", {
                required: "Mobile number is required",
              })}
              className="input-field"
            />
            {errors.mobile_no && (
              <p  style={{ textAlign: "left", width: "100%" }} className="error-message">{errors.mobile_no.message}</p>
            )}

            <textarea
              name="query"
              placeholder="QUERY"
              {...register("query", { required: "Query is required" })}
              className="input-field"
              rows="5"
            />
            {errors.query && (
              <p  style={{ textAlign: "left", width: "100%" }} className="error-message">{errors.query.message}</p>
            )}

            <button
              type="submit"
              className="submit-btn"
              style={{ fontFamily: "Arboria" }}
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
